import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import MarkdownContent from "../components/MarkdownContent";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export const AboutPageTemplate = ({ title, content, biography, resume, inspiration, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <article className="page page--about">
      <div className="container-md">
        <div className="row align-items-start justify-content-center">
          <div className="col-lg-8 col-md-12">
      <h1 className="title mb-3">{title}</h1>
      {/* <PageContent className="content" content={content} /> */}
      <section className="biography mb-5">
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-3">
            <h2>{biography.heading}</h2>
            <MarkdownContent className="biography-description" content={biography.description} />
          </div>
          <div className="col-md-6 col-sm-12">
            <PreviewCompatibleImage imageInfo={biography.image1} />
          </div>
        </div>
      </section>
      <section className="resume mb-5 pb-3 pt-md-3">
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-3 order-md-last">
            <h2>{resume.heading}</h2>
            <MarkdownContent className="content" content={resume.description} />
              { resume.file && resume.file.publicURL && (
              <p>
                <a href={resume.file.publicURL}>Download complete resume <span className="bi bi-file-earmark-pdf fs-4" title="PDF file"></span></a>
              </p>
              )}          
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
            <PreviewCompatibleImage imageInfo={resume.image1} />
          </div>
        </div>
      </section>
      </div></div></div>
      <section className="press-list bg-light my-0 py-5">
        <div className="container-md">
          <div className="row align-items-start">
            <div className="col">
              <h2 className="mb-4">Press List</h2>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </section>
      <section className="callout-video my-0 py-5 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mb-4">
                <div className="ratio ratio-16x9">
                  <iframe
                    src={inspiration.embed.url || "https://player.vimeo.com/video/15439151?h=80ae4eab64&title=0&byline=0&portrait=0"}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    referrerPolicy="origin-when-cross-origin"
                    title={inspiration.embed.title || "Video Player"}
                  ></iframe>
                </div>
              </div>
              <div className="col-md-4">
                <h2 className="h1">{inspiration.heading}</h2>
                <MarkdownContent className="inspiration-description" content={inspiration.description} />
              </div>
          </div>
        </div>
      </section>
    </article>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  biography: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  resume: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  inspiration: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const title = post.frontmatter.title || null;
  const description = post.frontmatter.biography.description.slice(0, 180) || data.excerpt || null;

  return (
    <Layout>
      <Helmet>
        {title ? <title>{title}</title> : null}
        {description ? <meta name="description" content={description} /> : null}
        {title ? <meta property="og:title" content={title} /> : null}
      </Helmet>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        biography={post.frontmatter.biography}
        resume={post.frontmatter.resume}
        inspiration={post.frontmatter.inspiration}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        biography {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
        resume {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          file {
            name
            extension
            publicURL
          }
        }
        inspiration {
          heading
          description
          embed {
            title
            url
          }
        }
      }
    }
  }
`;
